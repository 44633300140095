<template>
  <section
    id="nominations"
    class="section section--nominations section--notRadius"
  >
    <div class="section__wrapper">
      <h2 class="text-44 text-bold title-mb">Номинации</h2>
      <div v-if="!!nominationsData?.length === false">
        <h2 class="text-22 text-bold">Сейчас их нет, но скоро появятся</h2>
      </div>
      <ul v-else class="nominations__list">
        <NominationsCard
          v-for="nomination in sortedNominationData"
          :id="nomination?.id"
          :key="nomination?.id"
          :img="
            nomination.attributes?.photo?.data?.attributes?.formats?.large
              ?.url ?? null
          "
          :icon="nomination.attributes?.icon?.data?.attributes?.url ?? null"
          :title="nomination.attributes?.name"
          :slug="nomination.attributes?.slug"
          :description="nomination.attributes?.description"
          class="nominations__item"
        />
      </ul>
    </div>
  </section>
</template>
<script setup lang="ts">
import { getNominations } from "@/backend/composables/base"
const nominationsData = await getNominations()
const sortedNominationData = computed(() => {
  return nominationsData?.sort((a, b) => a.id - b.id)
})
</script>
<style lang="scss">
.section {
  &--nominations {
    margin: 0 0 -10px 0;
    padding: 0 70px 150px;
    @include md {
      margin: 0 0 0 0;
      padding: 0 36px 130px;
    }
    @include sm {
      padding: 0 16px 60px;
    }
  }
}
.nominations {
  &__list {
    display: grid;
    grid-template-areas:
      "n1 n1 n5 n6"
      "n2 n3 n5 n7"
      "n4 n4 n8 n8";
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 20px;
    @include md {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
        "n1 n1"
        "n2 n3"
        "n4 n4"
        "n5 n6"
        "n5 n7"
        "n8 n8";
    }
    @include sm {
      grid-template-columns: 1fr;
      grid-template-areas:
        "n1"
        "n2"
        "n3"
        "n4"
        "n5"
        "n6"
        "n7"
        "n8";
      gap: 20px;
    }
  }
  &__item {
    min-height: 260px;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    @include sm {
      min-height: 380px;
    }
    &:nth-child(1) {
      grid-area: n1;
    }
    &:nth-child(2) {
      grid-area: n2;
    }
    &:nth-child(3) {
      grid-area: n3;
    }
    &:nth-child(4) {
      grid-area: n4;
    }
    &:nth-child(5) {
      grid-area: n5;
      height: 100%;
    }
    &:nth-child(6) {
      grid-area: n6;
    }
    &:nth-child(7) {
      grid-area: n7;
    }
    &:nth-child(8) {
      grid-area: n8;
    }
  }
}
</style>
